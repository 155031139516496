import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useLocation, useNavigate } from 'react-router-dom';
import sarovarLogo from '../assets/images/sarovar-logo-final.png';
import userAvatar from '../assets/images/user-avatar.png';
import './reports-view.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ReportView = ({ token }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { reportId, reportName } = location.state || {};

    const [tableData, setTableData] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [charts, setCharts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('table'); // Track the current tab
    const [columnView, setColumnView] = useState(1); // Track the column view for charts
    const [sortedColumn, setSortedColumn] = useState({ column: null, order: 'asc' }); // For sorting

    useEffect(() => {
        const executeReport = async () => {
            if (!reportId) {
                setError("Invalid report ID");
                setLoading(false);
                return;
            }

            try {
                if (!token) {
                    setError('Token is missing. Please log in again.');
                    return;
                }

                const response = await fetch(`${API_BASE_URL}/reports/${reportId}/execute?access_token=${encodeURIComponent(token)}`);

                if (!response.ok) {
                    throw new Error(`Error fetching report: ${response.statusText}`);
                }

                const data = await response.json();
                setTableData(data.data || []);
                setMetadata(data.metadata || {});
                setCharts(data.charts || []);
            } catch (err) {
                console.error("Error executing report:", err);
                setError("Failed to load report data. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        executeReport();
    }, [reportId, token]);

    const exportToCSV = () => {
        const headers = Object.keys(tableData[0] || {}).map(col => `"${col}"`).join(',');
        const csvRows = tableData.map(row =>
            Object.keys(row).map(col => {
                let cellValue = row[col];
                if (metadata[col] === 'int64' || metadata[col] === 'float64') {
                    cellValue = new Intl.NumberFormat('en-US').format(cellValue);
                }
                if (typeof cellValue === 'string' && cellValue.includes(',')) {
                    cellValue = `"${cellValue}"`;
                }
                return cellValue;
            }).join(',')
        ).join('\n');

        const csvData = [headers, csvRows].join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'report_data.csv');
    };


    const handleColumnViewChange = (columns) => {
        setColumnView(columns);
    };

    const sortTable = (column) => {
        const order = sortedColumn.column === column && sortedColumn.order === 'asc' ? 'desc' : 'asc';
        const sortedData = [...tableData].sort((a, b) => {
            if (a[column] < b[column]) return order === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return order === 'asc' ? 1 : -1;
            return 0;
        });
        setSortedColumn({ column, order });
        setTableData(sortedData);
    };

    return (
        <div className="view-report-container" style={{ minWidth: '90vw', margin: '0 auto' }}>
            {/* Header */}
            <div className="view-report-header">
                <div className="header-content">
                    <div className="left-content">
                        <img src={sarovarLogo} alt="Sarovar Logo" className="logo" />
                        <div className='view-report-user-avatar'>
                            <img src={userAvatar} alt="User Avatar" className="view-report-user-avatar" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Tabs */}
            <div className="view-report-tabs-container">
                <button className={activeTab === 'table' ? 'active-tab' : ''} onClick={() => setActiveTab('table')}>Data View</button>
                <button className={activeTab === 'visualization' ? 'active-tab' : ''} onClick={() => setActiveTab('visualization')}>Chart View</button>
            </div>

            {loading && <p>Loading report...</p>}
            {error && <p className="error-message">{error}</p>}

            {!loading && !error && (
                <div className="tab-content" >
                    <button className="go-back-button"
                        style={{ marginTop: activeTab === 'table' ? '8vh' : activeTab === 'visualization' ? '12vh' : '0vh', marginLeft: activeTab === 'table' ? '88vw' : activeTab === 'visualization' ? '88vw' : '0vh' }}
                        onClick={() => navigate('/reports')}>Go To Dashboard</button>
                    {activeTab === 'table' && (
                        <div className="table-tab-content">
                            <div class="title-container">
                                <h1 className="report-title">{reportName} - Table</h1>
                                <div className="export-button-container">
                                    {tableData.length > 0 && (
                                        <button onClick={exportToCSV} className="export-button">
                                            <span className="download-text">Export To CSV</span>
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="data-table-container full-width">
                                <table style={{ width: '97vw', margin: '0 auto' }}>
                                    <thead>
                                        <tr>
                                            {tableData.length > 0 &&
                                                Object.keys(tableData[0]).map((col, index) => (
                                                    <th key={index} className="table-header" onClick={() => sortTable(col)}>
                                                        {col} <span className="datatype">({metadata[col] || 'N/A'})</span>
                                                        {sortedColumn.column === col && (sortedColumn.order === 'asc' ? ' 🔼' : ' 🔽')}
                                                    </th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                {Object.values(row).map((value, colIndex) => (
                                                    <td key={colIndex} className="table-cell">{value}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    {activeTab === 'visualization' && (
                        <div className="visualization-tab-content">
                            <div className="column-view-buttons-report-view">
                                <button className={columnView === 1 ? 'active' : ''} onClick={() => handleColumnViewChange(1)} disabled={charts.length < 1}>1 Column View</button>
                                <button className={columnView === 2 ? 'active' : ''} onClick={() => handleColumnViewChange(2)} disabled={charts.length < 2}>2 Column View</button>
                                <button className={columnView === 3 ? 'active' : ''} onClick={() => handleColumnViewChange(3)} disabled={charts.length < 3}>3 Column View</button>
                            </div>
                            <div className="charts-container" style={{
                                display: 'grid',
                                gap: '20px',
                                gridTemplateColumns: `repeat(${columnView}, 1fr)`
                            }}>
                                {charts.length > 0 ? (
                                    charts.map((chart, index) => (
                                        <div key={index} className="chart-container" style={{ height: columnView === 1 ? '65vh' : columnView === 2 ? '60vh' : '40vh', width: columnView === 1 ? '95vw' : columnView === 2 ? '47vw' : '32vw' }}>
                                            <Plot
                                                data={chart.data}
                                                layout={{
                                                    ...chart.layout,
                                                    title: `${reportName} - Chart ${index + 1}`,
                                                    autosize: true,
                                                    margin: { t: 50, l: 50, r: 50, b: 50 }
                                                }}
                                                useResizeHandler={true}
                                                style={{ width: '100%', height: '100%' }}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p>No visualizations available</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ReportView;