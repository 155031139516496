import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LandingPage from './landing-page';
import LoginPage from './login';
import Reports from './reports';
import ReportsView from './reports-view';

function App() {
  const [token, setToken] = useState('');
  const [socket, setSocket] = useState(null);

  return (
    <Router>
        <Routes>
          <Route path="/" element={<LoginPage setToken={setToken} setSocket={setSocket} />} />
          <Route path="/landing" element={<LandingPage token={token} socket={socket} />} />
          <Route path="/reports" element={<Reports token={token} />} /> {/* Pass token to Reports */}
          <Route path="/view-report" element={<ReportsView token={token} />} />
        </Routes>
    </Router>
  );
}

export default App;