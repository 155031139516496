import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sarovarLogo from '../assets/images/sarovar-logo-final.png'; // Logo path
import userAvatar from '../assets/images/user-avatar.png';
import './reports.css';



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Reports = ({ token }) => {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);

  // Fetch reports from backend and localStorage
  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (!token) {
          setError('Token is missing. Please log in again.');
          return;
        }

        // Fetch reports from backend API
        const response = await fetch(`${API_BASE_URL}/reports?access_token=${encodeURIComponent(token)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch reports from the backend.');
        }
        const backendReports = await response.json();

        // Fetch reports from localStorage
        const storedReports = JSON.parse(localStorage.getItem('reports')) || [];

        // Merge backend and localStorage reports (deduplicate by ID)
        const combinedReports = [
          ...storedReports,
          ...backendReports.filter(
            (backendReport) =>
              !storedReports.some((localReport) => localReport.id === backendReport.id)
          ),
        ];

        setReports(combinedReports); // Update state with combined reports
      } catch (err) {
        console.error('Error fetching reports:', err);
        setError('Failed to load reports. Please try again later.');
        // Load only localStorage reports as fallback
        const storedReports = JSON.parse(localStorage.getItem('reports')) || [];
        setReports(storedReports);
      }
    };

    fetchReports();
  }, [token]);

  // Navigate to view-report page with report ID
  const handleReportClick = (report) => {
    navigate(`/view-report`, { state: { reportId: report.chat_id || report._id, reportName: report.name } });
  };


  // Helper function to format the created_at date
  const formatDate = (isoDate) => {
    if (!isoDate) return 'N/A'; // Handle cases where created_at is null
    const date = new Date(isoDate);
    return date.toLocaleDateString(); // Format as a readable date
  };


  return (
    <div className="reports-container">
      {/* Header */}
      <div className="report-header">
        <div className="header-content">
          <img src={sarovarLogo} alt="Sarovar Logo" className="logo" />
          <div className='user-avatar-reports'>
            <img src={userAvatar} alt="User Avatar" className="user-avatar" />
          </div>
        </div>
      </div>

      <div>
        {/* Create New Report Button */}
        <div className="create-report-container">
          <button onClick={() => navigate('/landing')} className="create-report-button">
            + Create New Report
          </button>
          <h1 className="title">My Reports</h1>
        </div>

        {/* Error Message */}
        {error && <p className="error-message">{error}</p>}

        {/* List of Reports */}
        <div className="reports-list">
          {reports.length > 0 ? (
            reports.map((report) => (
              <div key={report.id || report._id} className="report-card" onClick={() => handleReportClick(report)}>
                <h3 className="report-name">{report.name}</h3>
                <p className="report-description">{report.description}</p> {/* Display Report Description */}
                <div className="report-divider"></div> {/* Optional Divider */}
                <p className="report-date">Created at: {formatDate(report.created_at)}</p>
              </div>
            ))
          ) : (
            <p>No reports available. Create one!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
